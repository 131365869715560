// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

  .Drawer-Title {
    display: flex;
    margin-top: 20px;
  }

  .Lists-Container {
    height: 92%;
    display: flex;
    overflow-x: auto;
  }

  .Title {
    color: #5A5A5A;
    font-family: Ubuntu;
    font-size: 25px;
    margin-left: 5%;
    padding-bottom: 15px;
  }

  .Project-Container {
    display: flex;
    flex-direction: column;
  }
`, "",{"version":3,"sources":["webpack://./client/styles/Project.css"],"names":[],"mappings":";;EAEE;IACE,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,eAAe;IACf,oBAAoB;EACtB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB","sourcesContent":["\n\n  .Drawer-Title {\n    display: flex;\n    margin-top: 20px;\n  }\n\n  .Lists-Container {\n    height: 92%;\n    display: flex;\n    overflow-x: auto;\n  }\n\n  .Title {\n    color: #5A5A5A;\n    font-family: Ubuntu;\n    font-size: 25px;\n    margin-left: 5%;\n    padding-bottom: 15px;\n  }\n\n  .Project-Container {\n    display: flex;\n    flex-direction: column;\n  }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
