// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card {
  position: relative;
  cursor: pointer;
  background: white;
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 0 rgba(9, 45, 66, 0.25);
  font-size: 15px;
  overflow-wrap: break-word;
  min-height: 18px;
}

.Card:hover {
  background: #f5f6f7;
}

.Card-Icons {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.Card-Button {
  background-color: rgba(51, 51, 51, 0.05);
  border-radius: 8px;
  border-width: 0;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 0;
  padding: 10px 12px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
`, "",{"version":3,"sources":["webpack://./client/styles/Card.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,qCAAqC;EACrC,yCAAyC;EACzC,eAAe;EACf,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,wCAAwC;EACxC,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,eAAe;EACf,qBAAqB;EACrB;cACY;EACZ,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,SAAS;EACT,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;EACrB,wBAAwB;EACxB,mBAAmB;EACnB,iBAAiB;EACjB,yBAAyB;EACzB,0BAA0B;AAC5B","sourcesContent":[".Card {\n  position: relative;\n  cursor: pointer;\n  background: white;\n  margin: 5px;\n  padding: 10px;\n  border-radius: 5px;\n  border: 1px solid rgba(0, 0, 0, 0.12);\n  box-shadow: 0 1px 0 rgba(9, 45, 66, 0.25);\n  font-size: 15px;\n  overflow-wrap: break-word;\n  min-height: 18px;\n}\n\n.Card:hover {\n  background: #f5f6f7;\n}\n\n.Card-Icons {\n  position: absolute;\n  top: 5px;\n  right: 5px;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n\n.Card-Button {\n  background-color: rgba(51, 51, 51, 0.05);\n  border-radius: 8px;\n  border-width: 0;\n  color: #333333;\n  cursor: pointer;\n  display: inline-block;\n  font-family: \"Haas Grot Text R Web\", \"Helvetica Neue\", Helvetica, Arial,\n    sans-serif;\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  list-style: none;\n  margin: 0;\n  padding: 10px 12px;\n  text-align: center;\n  transition: all 200ms;\n  vertical-align: baseline;\n  white-space: nowrap;\n  user-select: none;\n  -webkit-user-select: none;\n  touch-action: manipulation;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
