// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Edit-Card .Card {
  min-height: 50px;
  padding-left: 8px;
  padding-right: 15px;
}

.Edit-Card .Card:hover {
  background: white;
}

.Edit-Card-Textarea {
  width: 100%;
  border: none;
  resize: none;
  outline: none;
  font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./client/styles/CardEditor.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,eAAe;AACjB","sourcesContent":[".Edit-Card .Card {\n  min-height: 50px;\n  padding-left: 8px;\n  padding-right: 15px;\n}\n\n.Edit-Card .Card:hover {\n  background: white;\n}\n\n.Edit-Card-Textarea {\n  width: 100%;\n  border: none;\n  resize: none;\n  outline: none;\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
