// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Add-List-Editor {
  background: #dfe3e6;
  border-radius: 5px;
  padding: 2px 2px;
}
`, "",{"version":3,"sources":["webpack://./client/styles/AddList.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".Add-List-Editor {\n  background: #dfe3e6;\n  border-radius: 5px;\n  padding: 2px 2px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
