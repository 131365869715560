// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Board {
  height: 92%;
  display: flex;
  overflow-x: auto;
}

.Add-List {
  width: 272px;
  margin: 10px;
  flex-shrink: 0;
}

.Add-List-Button {
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  min-height: 32px;
  padding: 5px 8px;
  transition: background-color 85ms ease-in, opacity 40ms ease-in,
    border-color 85ms ease-in;
  height: fit-content;
}

.Add-List-Button:hover {
  /* background-color: rgba(0, 0, 0, 0.24); */
  background-color: rgba(90, 90, 90, 1);
}

.Add-List-Button ion-icon {
  margin-right: 1px;
}
`, "",{"version":3,"sources":["webpack://./client/styles/Board.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,qCAAqC;EACrC,mBAAmB;EACnB,eAAe;EACf,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;EAChB;6BAC2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,2CAA2C;EAC3C,qCAAqC;AACvC;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".Board {\n  height: 92%;\n  display: flex;\n  overflow-x: auto;\n}\n\n.Add-List {\n  width: 272px;\n  margin: 10px;\n  flex-shrink: 0;\n}\n\n.Add-List-Button {\n  background-color: rgba(0, 0, 0, 0.12);\n  border-radius: 10px;\n  cursor: pointer;\n  color: #fff;\n  display: flex;\n  align-items: center;\n  min-height: 32px;\n  padding: 5px 8px;\n  transition: background-color 85ms ease-in, opacity 40ms ease-in,\n    border-color 85ms ease-in;\n  height: fit-content;\n}\n\n.Add-List-Button:hover {\n  /* background-color: rgba(0, 0, 0, 0.24); */\n  background-color: rgba(90, 90, 90, 1);\n}\n\n.Add-List-Button ion-icon {\n  margin-right: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
