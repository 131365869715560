// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.List {
  background: #dfe3e6;
  flex-shrink: 0;
  width: 272px;
  height: fit-content;
  margin: 10px;
  margin-right: 0;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.List-Title {
  display: flex;
  font-size: 20px;
  justify-content: center;
  cursor: pointer;
  font-family: Ubuntu;
  overflow-wrap: break-word;
  padding: 10px;
}

.Toggle-Add-Card {
  cursor: pointer;
  padding: 10px;
  color: #6b808c;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
}

.Toggle-Add-Card:hover {
  background-color: rgba(9, 45, 66, 0.13);
  color: #17394d;
  text-decoration: underline;
}

.Toggle-Add-Card ion-icon {
  margin-right: 2px;
}
`, "",{"version":3,"sources":["webpack://./client/styles/list.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,cAAc;EACd,YAAY;EACZ,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,mBAAmB;EACnB,qCAAqC;AACvC;;AAEA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,eAAe;EACf,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,aAAa;EACb,cAAc;EACd,4BAA4B;EAC5B,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,uCAAuC;EACvC,cAAc;EACd,0BAA0B;AAC5B;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".List {\n  background: #dfe3e6;\n  flex-shrink: 0;\n  width: 272px;\n  height: fit-content;\n  margin: 10px;\n  margin-right: 0;\n  border-radius: 10px;\n  border: 1px solid rgba(0, 0, 0, 0.12);\n}\n\n.List-Title {\n  display: flex;\n  font-size: 20px;\n  justify-content: center;\n  cursor: pointer;\n  font-family: Ubuntu;\n  overflow-wrap: break-word;\n  padding: 10px;\n}\n\n.Toggle-Add-Card {\n  cursor: pointer;\n  padding: 10px;\n  color: #6b808c;\n  border-radius: 0 0 10px 10px;\n  display: flex;\n  align-items: center;\n}\n\n.Toggle-Add-Card:hover {\n  background-color: rgba(9, 45, 66, 0.13);\n  color: #17394d;\n  text-decoration: underline;\n}\n\n.Toggle-Add-Card ion-icon {\n  margin-right: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
